import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import TitleBar from './components/TitleBar'
import Main from './components/Main'
import Menu from './components/Menu'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }

    this.onOpenMenu = this.onOpenMenu.bind(this)
    this.onBurgerClicked = this.onBurgerClicked.bind(this)
  }

  onOpenMenu (open) {
    this.setState({ menuOpen: open })
  }

  onBurgerClicked () {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render () {
    return (
      <BrowserRouter>
        <TitleBar
          menuOpen={this.state.menuOpen}
          onBurgerClicked={this.onBurgerClicked}
        />
        <Menu menuOpen={this.state.menuOpen} />
        <Main />
      </BrowserRouter>
    )
  }
}

export default App
