import React from 'react'
import Section from '../Section'
import Language from './Language'
import Expertise from './Expertise'
import Education from './Education'
import './index.css'

class RightColumn extends React.Component {
  render () {
    return (
      <div className='RightColumn'>
        <Section header='Expertise'>
          <Expertise />
        </Section>
        <Section header='Language'>
          <Language />
        </Section>
        <Section header='Education'>
          <Education><span role="img" aria-label="scholar">🎓</span> Northwestern Polytechnic University, Master in Software Engineering, 2005</Education>
          <Education><span role="img" aria-label="scholar">🎓</span> Northwestern Polytechnic University, Bachelor in Computer Science, 2002</Education>
        </Section>
        <Section header='Qualifications'>
          <p className='SectionDetails'>International Software Testing Qualifications Board (ISTQB) Certified.</p>
        </Section>
      </div>
    )
  }
}

export default RightColumn
