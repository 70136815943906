import React from 'react'
import { Icon } from 'antd'
import Banner from '../Banner'
import './index.css'

class Home extends React.Component {
  render () {
    return (
      <div className='Home'>
        <div className='contentWrap'>
          <Banner />
          <div className='paragraph'>
            <p>Automation. Tooling. DevOps. This is me.</p>
            <p>I am a software engineer based in Stockholm. I like creating software that solves difficult problems, making tools that simplify other people's daily work, and building solutions that enable the rapid software delivery pipeline.</p>
            <p>I work with containers based cloud services on a daily basis. And I am comfortable programming with C++, Java, Javascript, Python, Ruby, Bash, etc. </p>
            <p>Currently I am actively working with Python, Javascript, Docker, Kubernetes, Amazon Web Services (AWS), Jenkins, React, Selenium, TestCafe, Cucumber, etc.</p>
          </div>
        </div>
        <div className='footer'>
          <div className='footerItem'>&copy; Daniel Han</div>
          <div className='contactCell footerItem'><a href='https://github.com/hex0cter'><Icon type="github" className='contactIcon' /></a></div>
        </div>
      </div>
    )
  }
}

export default Home
