import React from 'react'
import Burger from '../Burger'
import './index.css'

function TitleBar (props) {
  return (
    <div className='TitleBar'>
      <Burger
        menuOpen={props.menuOpen}
        onBurgerClicked={props.onBurgerClicked}
      />
      <div className='titleList'>
        <div className='title hvr-underline-from-left'><a href="/">Home</a></div>
        <div className='title hvr-underline-from-left'><a href="https://web2md.danielhan.dev">Web to Markdown</a></div>
        <div className='title hvr-underline-from-left'><a href="https://schedule.danielhan.dev">Schedule Keeper</a></div>
        <div className='title hvr-underline-from-left'><a href="https://carnet.danielhan.dev/">Tech Notes</a></div>
        <div className='title hvr-underline-from-left'><a href="https://notes.danielhan.dev/">Notepad</a></div>
      </div>
    </div>
  )
}

export default TitleBar
