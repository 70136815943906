import React from 'react'
import './index.css'

class Banner extends React.Component {
  render () {
    return (
      <div className='banner'>
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div className="bannerTitle">
          <span className="letter">W</span>
          <span className="letter">h</span>
          <span className="letter">o</span>
          <span className="space"></span>
          <span className="letter">a</span>
          <span className="letter">m</span>
          <span className="space"></span>
          <span className="letter">I</span>
          <span className="letter">?</span>
        </div>
        <div className="shooting-stars"></div>
      </div>
    )
  }
}

export default Banner
