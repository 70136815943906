import React from 'react'
import MenuItem from '../MenuItem'
import './index.css'

function Menu (props) {
  if (!props.menuOpen) {
    return null
  }

  return (
    <div className='Menu'>
      <MenuItem url='/home' icon='home'>Home</MenuItem>
      <MenuItem url='https://web2md.danielhan.dev/' icon='file-markdown'>Web to Markdown</MenuItem>
      <MenuItem url='https://schedule.danielhan.dev/' icon='check-circle'>Schedule Keeper</MenuItem>
      <MenuItem url='https://carnet.danielhan.dev/' icon='clock-circle'>Tech Notes</MenuItem>
      <MenuItem url='https://notes.danielhan.dev/' icon='edit'>Notepad</MenuItem>
    </div>
  )
}

export default Menu
