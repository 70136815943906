import React from 'react'
import './index.css'

function Burger (props) {
  return (
    <button className={`Burger hamburger hamburger--collapse ${props.menuOpen ? 'is-active' : ''}`} type="button" onClick={props.onBurgerClicked}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}

export default Burger
